const content = [
  {
    id: 1,
    title: 'Tech-Stack',
    body: 'These are the Languages, Technologies, Libraries and Tools Im familiar with. Keep coming back this list will never stop expanding. You may also check-out my github for specifications on the tech. used in each project displayed in the carousel below. ',
    image: 'illustration-grow-together.svg',
  },
  {
    id: 2,
    title: 'My Code Treks',
    body: 'My Code Treks begin in 2018 when I started turning my .xls documents into python files. Back then, I saw the opportunity to reduce the amount of time I spent for data-entry and reporting through automation. Since then I began spending my weekends to small projects of data-analysis and finally I applyed to <SALT>. I am currently focusing on JS for both FE & BE, creating a full-stack search and display tool for SALT sales team.',
    image: 'illustration-your-users.svg',
  },
]

export default content
